// SEASON 2 SESSION 1
//season 2 CHAPTER 8 Alone                     //f447321942a15b8bf301a9df6271eb99
import React, { Component } from 'react'
import { graphql } from "gatsby"
import {
    Nav, Row, Col, 
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { 
    book, bookurl,
    // messages
 } from '../../../util/book.js'
import Share from '../../../components/share'
import SessionMenuV2 from '../../../components/sessionMenuV2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAngleLeft,
    faAngleRight,
    // faAngleDoubleLeft,
    // faAngleDoubleRight,
    faHome
 } from '@fortawesome/free-solid-svg-icons'

import TypefaceResize from '../../../components/typefaceResize'

import Layout from '../../../components/layout'
import LocalImage from '../../../components/local-image';
import '../../../css/pages.css'

class BookArw1SeasonTwoChp0 extends Component {
    render () {

        const chpNum = "chp0";
        const bookNum = chpNum.match(/\d+/g)[0];
        const picName = "s2chp0";
        const bookName = "arwS2"
        const prevBookNum = (parseInt(bookNum)-1).toString();
        const nextBookNum = (parseInt(bookNum)+1).toString();

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    const lang = i18n.language;
                    // const session = "2";
                    const story = {
                        "title": {    
                            "en": `${book[chpNum][lang]} - ${book[bookName][bookNum][lang]}`,
                            "zh-hk": `${book[chpNum][lang]} - ${book[bookName][bookNum][lang]}`,
                            "zh-cn": `${book[chpNum][lang]} - ${book[bookName][bookNum][lang]}`
                        },
                        // "cover":"https://acidrainworld.cdn.prismic.io/acidrainworld/c00ae184e39b344736ca2e12710f7f83d3cb08ea_s1c1.1_cover.jpg"
                    }

                    return (
                        <>
                        <Layout siteData={{ title:`${story.title[lang]}`, url:this.props.location.href, img:story.cover, 
                            desc:`${t("TEXT000109")} | ${t("TEXT180007")} | ${book[chpNum][lang]} : ${book[bookName][bookNum][lang]}`  }}>
                            
                            <div id="bodyPage" className="body-page body-bg">
                                <main role="main" className="main">
                                <Container>
                                    <Row className="bookMenu">
                                        <Col xs={8} className="d-flex align-items-center pr-0">
                                            <SessionMenuV2 book={bookName} lang={lang} select={bookNum}/> 
                                        </Col>
                                        <Col xs={4} className="d-flex justify-content-end mt-2 pl-0">
                                            <div className="rciMenu"><Nav.Link href={"/"+lang} className="icon"><FontAwesomeIcon icon={faHome} /></Nav.Link></div>
                                            <TypefaceResize target="#story-content" />
                                            <Share url={"/"+lang+bookurl[bookName][bookNum]} title="Share" />
                                        </Col>
                                    </Row>



                                    <Row id="story-content" className="bookContent">
                                        <Col className="px-0">

                                            <h2 className="chapter">{book[chpNum][lang]} {book[bookName][bookNum][lang]}</h2>
                                            {/* <div className="cover"><img className="img-fluid w-100" alt="cover" src={story.cover}/></div> */}
                                            <div className="cover"><LocalImage name={picName} attr={{className: "img-fluid w-100", alt:`cover`}}/></div>
                                            
                                            {/* <h3 className="session">{story.session[lang]}</h3> */}

                            {
                                (lang === 'en') ? 
                                    <>
<p></p><p></p>
{/* <p className="text-right">Original Story : Kit Lau</p>
<p className="text-right">Author : Perl Grey</p>
<p className="text-right">Translator : Johnny Ko</p> */}

                                    </>
                                :
                                (lang === 'zh-hk') ?
                                    <>
<p></p><p></p>
{/* <p className="text-right">原作 : 劉斯傑</p>
<p className="text-right">小說作者 : 佩格雷</p> */}
                                    </>
                                :
                                (lang === 'zh-cn') ?
                                    <>
<p></p><p></p>
{/* <p className="text-right">原作 : 刘斯杰</p>
<p className="text-right">小说作者 : 佩格雷</p> */}
                                    </>
                                :
                                    null
                            }

                            <p></p>
                            <p></p>

                                        </Col>
                                    </Row>
                                    <Row className="bookPreNext">
                                        <Col>
                                            <Nav.Link className="btn" href={"/"+lang+bookurl[bookName][prevBookNum]}><FontAwesomeIcon icon={faAngleLeft} /></Nav.Link>
                                        </Col>
                                        <Col>
                                            <Nav.Link className="btn" href={"/"+lang+bookurl[bookName][nextBookNum]}><FontAwesomeIcon icon={faAngleRight} /></Nav.Link>
                                        </Col>
                                    </Row>

                                </Container>

                                </main>
                            </div>
                        </Layout>
                        </>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(BookArw1SeasonTwoChp0)

export const query = graphql`
query BookArw1SeasonTwoChp0Lang($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`
