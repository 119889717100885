import React, { Component } from 'react'

import {
  NavDropdown,
  Nav
} from 'react-bootstrap'


import { book, bookurl, messages } from './../util/book.js'
import './sessionMenu.css'


class SessionMenuV2 extends Component {

    render() {
        const lang = this.props.lang || 'en'
        const path = '/'+lang
        const sid = {}
        sid[this.props.select] = "active";
        const bookName = this.props.book;
        // console.log(sid)

        const ddList = [];
        const latestChp = {
            'arwS1': 10,    //--- CHANGE latest chapter
            'arwS2': 0,    //--- CHANGE latest chapter
        };     //--- CHANGE latest chapter
        for (var chp = 0; chp <= latestChp[bookName]; chp++) {
          ddList.push(<NavDropdown.Item href={path+bookurl[bookName][chp]} className={sid[chp]}>{chp} &nbsp; {book[bookName][chp][lang]}</NavDropdown.Item>);
        }

        return (
            <>
            <Nav id="sessionMenu">
                <NavDropdown title={messages[lang].TEXT180010} id="dropdown-world">
                    {ddList}
                    {/* <NavDropdown.Item href={path+bookurl["0"]} className={sid["0"]}>0 &nbsp; {book["0"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["1"]} className={sid["1"]}>1 &nbsp; {book["1"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["2"]} className={sid["2"]}>2 &nbsp; {book["2"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["3"]} className={sid["3"]}>3 &nbsp; {book["3"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["4"]} className={sid["4"]}>4 &nbsp; {book["4"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["5"]} className={sid["5"]}>5 &nbsp; {book["5"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["6"]} className={sid["6"]}>6 &nbsp; {book["6"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["7"]} className={sid["7"]}>7 &nbsp; {book["7"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["8"]} className={sid["8"]}>7 &nbsp; {book["8"][lang]}</NavDropdown.Item> */}
                </NavDropdown>
            </Nav>
            </>
        )
    }

}

export default SessionMenuV2
